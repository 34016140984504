import React from 'react';
import { Hidden } from '@material-ui/core';
import { PAIDY_HOST } from 'src/constants';
import CashbackBannerBottom from 'src/components/Merchant/Campaign/GeneralCashback202303/CashbackBannerBottom';
import Campaign1Img from 'src/images/lottery-202303/campaign-1.png';
import Campaign2Img from 'src/images/lottery-202303/campaign-2.png';
import styles from './Lottery202303BannerBottom.module.scss';

const URL_1 = `${PAIDY_HOST}/campaign/general_cashback_202303/`;
const URL_2 = `${PAIDY_HOST}/campaign_202303/`;

const TITLE_CASHBACK_BANNER_BOTTOM = (
  <>
    <Hidden xsDown>
      ペイディが使えるすべてのお店が対象のキャンペーンも同時開催！
      <br />
      お得なこのチャンスをお見逃しなく！
    </Hidden>
    <Hidden smUp>
      <p className={styles.titleSp}>
        ペイディが使えるすべてのお店が対象
        <br />
        のキャンペーンも同時開催！
        <br />
        お得なこのチャンスをお見逃しなく！
      </p>
    </Hidden>
  </>
);

export default function Lottery202303BannerBottom() {
  return (
    <CashbackBannerBottom
      title={TITLE_CASHBACK_BANNER_BOTTOM}
      img1={Campaign1Img}
      url1={URL_1}
      img2={Campaign2Img}
      url2={URL_2}
    />
  );
}
