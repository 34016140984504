import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import JulyCondition from 'src/components/Merchant/Campaign/Lottery2022/JulyConditions';

const DATA = [
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2023年3月16日（木）6:00～4月15日（土）23:59',
  },
  {
    title: <strong>キャンペーン概要・特典</strong>,
    content: (
      <>
        キャンペーン期間中に対象ショップの決済画面であと払い（ペイディ）を選択し、翌月あと払いもしくは分割手数料無料*の
        <OutboundLink
          href="https://paidy.com/npay"
          target="_blank"
          rel="noopener noreferrer"
        >
          ３・６回あと払い
        </OutboundLink>
        で1回あたり1,000円以上購入すると、抽選で1万名様に1,000円をキャッシュバック。
        <br />
        <br />
        <small>
          *口座振替・銀行振込のみ無料
          <br />
          ※お一人様につき1回まで。
          <br />
          ※３回あと払いはすべてのお店で利用可能です。６回あと払いが使えるお店は
          <OutboundLink
            href="https://paidy.com/npay"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </OutboundLink>
          からご確認ください。
          <br />
          ※３回あと払いは1回のお買い物につき3,000円以上、6回あと払いは1回のお買い物につき6,000円以上のお支払いで利用可能です。
          <br />
          <br />
          キャンペーン終了後、当選者には2023年4月30日までに特典分がキャッシュバックされる予定です。
          キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
        </small>
      </>
    ),
  },
  {
    title: <strong>キャンペーン対象</strong>,
    content: '対象ショップの決済画面であと払い（ペイディ）をご利用のお客様',
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・キャンペーン期間中の対象となる決済毎に1回としてカウントし、抽選は決済毎に行われます。
        <br />
        ・同期間に開催中の他のキャンペーンと併せて月間当選上限額は10万円になります。
        <br />
        ・キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。
        <br />
        ・キャンペーン終了後2023年4月30日までに特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。
        <br />
        ・キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当キャッシュバックが付与されます。あらかじめご了承ください。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <OutboundLink
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディキャッシュバック及びクーポン利用規約
        </OutboundLink>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <OutboundLink
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ヘルプ
        </OutboundLink>
        からご連絡ください。
      </>
    ),
  },
];

export default function Lottery202303Condition() {
  return <JulyCondition data={DATA} />;
}
