import React from 'react';
import BannerImg from 'src/images/lottery-202303v2/banner.png';
import BannerSpImg from 'src/images/lottery-202303v2/banner-sp.png';
import { Hidden } from '@material-ui/core';
import styles from './TopBannerV2.module.scss';

export default function TopBannerV2({
  imgPC = BannerImg,
  imgSP = BannerSpImg,
}) {
  return (
    <section className={styles.section}>
      <Hidden xsDown>
        <img alt="hero-banner" src={imgPC} className={styles.banner} />
      </Hidden>
      <Hidden smUp>
        <img alt="hero-banner-sp" src={imgSP} className={styles.banner} />
      </Hidden>
      <div className={styles.disclaimer}>
        <h1 className={styles.title}>
          抽選は決済ごとに行われるため、
          <Hidden smUp>
            <br />
          </Hidden>
          ペイディを使えば使うほど
          <Hidden smUp>
            <br />
          </Hidden>
          <span className={styles.textLinear}>当選確率アップ</span>！
        </h1>
      </div>
    </section>
  );
}
