import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import BannerBottom1Img from 'src/images/general-cashback-202303/banner-bottom-1.png';
import BannerBottom2Img from 'src/images/general-cashback-202303/banner-bottom-2.png';
import styles from './CashbackBannerBottom.module.scss';

const isProduction = process.env.GATSBY_ENVIRONMENT === 'production';

const BANNER_1_URL = isProduction
  ? 'https://paidy.com/campaign/lottery202303/'
  : 'https://paidy-staging.com/campaign/lottery202303/';

const BANNER_2_URL = isProduction
  ? 'https://paidy.com/campaign_202303/'
  : 'https://paidy-staging.com/campaign_202303/';

export default function CashbackBannerBottom({
  title,
  img1 = BannerBottom1Img,
  url1 = BANNER_1_URL,
  img2 = BannerBottom2Img,
  url2 = BANNER_2_URL,
  noImg2 = false,
  id,
}) {
  return (
    <section className={styles.section} id={id}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.container}>
        <OutboundLink href={url1} target="_blank" rel="noopener noreferrer">
          <img alt="banner-bottom-1" src={img1} width={311} height={336} />
        </OutboundLink>
        {!noImg2 && (
          <OutboundLink href={url2} target="_blank" rel="noopener noreferrer">
            <img alt="banner-bottom-2" src={img2} width={311} height={336} />
          </OutboundLink>
        )}
      </div>
    </section>
  );
}
