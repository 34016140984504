import React from 'react';
import QrAndBadgesSwitch from 'src/components/QrAndBadgesSwitch';
import CartImg from 'src/images/6pay/cart.png';
import PayStep3Img from 'src/images/6pay/pay-step-3.png';
import PayStep4Img from 'src/images/6pay/pay-step-4.png';
import UseInstallments from 'src/components/Landing/UseInstallments';
import QrCodeImg from 'src/images/lottery-202303v2/qr_v2.png';

const ITEMS = [
  {
    ImgComponent: () => (
      <QrAndBadgesSwitch
        qrCodeImg={QrCodeImg}
        url="https://paidy.onelink.me/SQfW/oem13h6x"
      />
    ),
    figCaption: 'ペイディアプリから本人確認する。',
  },
  {
    ImgComponent: () => (
      <img alt="cart" src={CartImg} width={184} height={184} />
    ),
    figCaption: 'ショップで欲しい商品をカートに入れる。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-3" src={PayStep3Img} width={184} height={184} />
    ),
    figCaption:
      'お支払い方法で「あと払い（ペイディ）」を選択して、メールアドレスと携帯電話番号を入力する。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-4" src={PayStep4Img} width={184} height={184} />
    ),
    figCaption:
      'ご希望のお支払い回数を選んで完了。一括払いで購入後に、ペイディアプリから変更することも可能です。',
  },
];

export default function UseInstallmentsV2() {
  return <UseInstallments data={ITEMS} />;
}
