import React from 'react';
import Layout from 'src/components/Layout/Layout';
import Lottery202303Condition from 'src/components/Merchant/Campaign/Lottery202303/Lottery202303Condition';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import Lottery202303PaymentSteps from 'src/components/Merchant/Campaign/Lottery202303/Lottery202303PaymentSteps';
import Lottery202303NPay from 'src/components/Merchant/Campaign/Lottery202303/Lottery202303NPay';
import UseInstallmentsV2 from 'src/components/Merchant/Campaign/Lottery202303v2/UseInstallmentsV2';
import Lottery202303BannerBottom from 'src/components/Merchant/Campaign/Lottery202303/Lottery202303BannerBottom';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyAppDownload';
import { SITE_METADATA, PAIDY_HOST } from 'src/constants';
import OgpImg from 'src/images/campaign-202303/202303_TCMP_OGP_v1.jpg';
import TopBannerV2 from 'src/components/Merchant/Campaign/Lottery202303v2/TopBannerV2';

const SEOProps = {
  ogImage: OgpImg,
  twitterImage: `${PAIDY_HOST}/tvcm_ogp_v2.jpg`,
  title: SITE_METADATA.lottery202303v2.title,
  description: SITE_METADATA.lottery202303v2.description,
};

export default function Lottery202303V2() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <TopBannerV2 />
      <Lottery202303Condition />
      <Lottery202303PaymentSteps />
      <Lottery202303NPay />
      <UseInstallmentsV2 />
      <Lottery202303BannerBottom />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
