import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Hidden } from '@material-ui/core';
import NPayImg from 'src/images/lottery-202303/npay.png';
import styles from './Lottery202303NPay.module.scss';

export default function Lottery202303NPay() {
  return (
    <section className={styles.section}>
      <div className={styles.card}>
        <Hidden xsDown>
          <img alt="3pay" src={NPayImg} width={490} height={400} />
        </Hidden>
        <Hidden smUp>
          <img alt="3pay" src={NPayImg} width={280} height={228} />
        </Hidden>
        <div className={styles.right}>
          <h3 className={styles.title}>
            分割手数料無料*
            <br />
            ３・６回あと払いで、
            <br />
            お買い物上手に
          </h3>
          <p className={styles.text}>
            あと払い（ペイディ）なら、３・6回あと払いが分割手数料無料*。お支払い総額はそのまま、月々のお支払い金額を調整できるので、かしこくお買い物を楽しめます。
          </p>
          <p className={styles.small}>
            *口座振替・銀行振込のみ無料
            <br />
            ※３回あと払いはすべてのお店で利用可能。６回あと払いが使えるお店は
            <OutboundLink
              href="https://paidy.com/npay"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </OutboundLink>
            からご確認ください。
          </p>
        </div>
      </div>
    </section>
  );
}
